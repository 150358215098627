<template>
  <v-container class="login-container" fluid>
    <v-row style="height: 100%; margin:0px">
      <v-col cols="12" md="6" lg="8" class="bottom-gradient hidden-sm-and-down">
      </v-col>
      <v-col cols="12" md="6" lg="4" class="input_section">
        <v-card
          class="mr-0 my-0 py-0 input_section"
          color="#F5F9FA"
          style="height: 100%; padding-left: 30px; padding-right: 30px;"
        >
          <v-card-title class="justify-center pt-15">
            <v-img
              src="@/assets/logo_login.svg"
              width="150"
              height="150"
              contain
            />
          </v-card-title>
          <v-card-title class="mx-3 mt-15">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  label="Email Address"
                  dense
                  rounded
                  filled
                  background-color="white"
                  class="mt-3"
                  @change="onChange"
                  full-width
                  hide-details
                  type="email"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  @change="onChange"
                  @keyup.enter="loginClicked"
                  label="Password"
                  dense
                  rounded
                  filled
                  full-width
                  background-color="white"
                  class="pb-0 mb-0"
                />
                <v-alert type="error" v-if="errorMessage">
                  {{ errorMessage }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title>
            <v-btn
              large
              rounded
              color="#6038F7"
              width="100%"
              @click="loginClicked"
              :loading="loading"
              dark
              >Log In</v-btn
            >
          </v-card-title>
          <v-card-title class="justify-center">
            <v-btn
              text
              class="text-none mb-4"
              rounded
              @click="goToForgotPassword"
              >Forgot your password?</v-btn
            >
          </v-card-title>
          <v-card-text
            class="justify-center align-center d-flex"
            style="font-weight:bold; font-style: italic;"
          >
            OR
          </v-card-text>
          <v-card-title class="justify-center d-flex flex-column">
            <v-btn
              color="white"
              class="mb-4 primary--text"
              rounded
              block
              @click="gotoVolunteerRegister"
              >Register as a Volunteer</v-btn
            >
            <v-btn
              color="white"
              class="mb-4 primary--text"
              rounded
              block
              @click="gotoAgencyRegister"
              >Join as an Agency</v-btn
            >
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      errorMessage: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 6) || "Min 6 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      showPassword: false,
    };
  },
  methods: {
    ...mapActions({
      onLogin: "auth/onLogin",
      getRegionSettings: "usersetting/getRegionSettings",
    }),
    loginClicked() {
      if (this.loading) return;
      const params = {
        email: this.email,
        password: this.password,
        rememberme: true,
      };
      this.loading = true;
      this.onLogin(params)
        .then((type) => {
          this.loading = false;
          this.emailError = null;
          this.passwordError = null;
          this.getRegionSettings();

          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else if (type == "Admin") {
            this.$router.push({ name: "admin-home" });
          } else if (type == "Agency") {
            this.$router.push({
              name: "agency-home",
              query: { welcome: true },
            });
          } else if (type == "Volunteer") {
            this.$router.push({
              name: "volunteer-home",
              query: { welcome: true },
            });
          } else {
            this.errorMessage = "unknown user type";
          }
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    gotoVolunteerRegister() {
      this.$router.push({ name: "user-register" });
    },
    gotoAgencyRegister() {
      this.$router.push({ name: "agency-register" });
    },
    goToForgotPassword() {
      this.$router.push({ name: "forgot-password" });
    },
  },
};
</script>
<style scoped>
.welcome-title {
  font-family: "Poppins-Bold";
  font-size: 70px;
  text-shadow: 2px 2px 12px #0006;
  color: #472583;
  margin-left: 50px;
  margin-top: 60%;
  margin-bottom: 10px;
  display: block;
}
.welcome-description {
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 50px;
  color: #240a52;
  display: block;
}
.login-container {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.bottom-gradient {
  background-image: url(../../assets/bg_login.jpg);
  background-size: cover;
  background-position: 50% 50%;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.login_img {
  -webkit-filter: drop-shadow(1px 8px 12px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(1px 8px 12px rgba(0, 0, 0, 0.4));
  margin-top: 70%;
}
.input_section {
  height: 100%;
  padding: 0px;
}
</style>
